<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card card-body printableArea" v-else>
      <h3>
        <b>Detail Hasil Tindaklanjut</b>
      </h3>
      <hr />
      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <gtTabs>
            <gtTab title="Hasil Tindaklanjut">
              <div class="col-md-12 pull-left">
                <!-- <small class="text-muted">Nomor Kendali</small>
                <h6>
                  {{ state.detail.no_opd === "" ? "-" : state.detail.no_opd }}
                </h6> -->
                <small class="text-muted">Nomor Surat</small>
                <h6>
                  {{
                    state.detail.nomorsurat === ""
                      ? "-"
                      : state.detail.nomorsurat
                  }}
                </h6>
                <small class="text-muted">Isi Ringkas</small>
                <h6>
                  {{
                    state.detail.isi_ringkas === ""
                      ? "-"
                      : state.detail.isi_ringkas
                  }}
                </h6>
                <br />
                <br />
                <hr />
              </div>
              <br />
              <br />
              <div class="col-md-12"><b>Detail Ringkasan</b></div>

              <!-- List Ringkasan Hasil Tindak Lanjut Level Struktural -->
              <div class="col-md-12 pull-left">
                <table class="table table-striped">
                  <tr>
                    <th><b>Nama</b></th>
                    <th><b>Ringkasan</b></th>
                  </tr>
                  <span
                    v-if="state.detail.nama === undefined"
                  ></span>
                  <tr
                    v-else-if="!state.detail.nama.length"
                    class="text-center"
                  >
                    <td colspan="4"><i>Ringkasan tidak ada</i></td>
                  </tr>
                  <tr
                    v-for="ringkasans in state.detail"
                    v-bind:key="ringkasans.id"
                  >
                    <td>{{ ringkasans.nama }}</td>
                    <td>{{ ringkasans.ringkasan }}</td>
                  </tr>
                </table>
                <hr />
              </div>
              <!-- List Ringkasan Hasil Tindak Lanjut Level Struktural -->

              <!-- <div class="col-md-12"><b>Detail Ringkasan Pelaksana</b></div> -->

              <!-- List Ringkasan Hasil Tindak Lanjut Level Pelaksana -->
              <!-- <div class="col-md-12 pull-left">
                <table class="table table-striped">
                  <tr>
                    <th>Pelaksana</th>
                    <th>Ringkasan</th>
                  </tr>
                  <span
                    v-if="state.detail.hasiltindaklanjutp === undefined"
                  ></span>
                  <tr
                    v-else-if="!state.detail.hasiltindaklanjutp.length"
                    class="text-center"
                  >
                    <td colspan="4">
                      <i>Ringkasan tidak ada</i>
                    </td>
                  </tr>
                  <tr
                    v-for="ringkasanp in state.detail.hasiltindaklanjutp"
                    v-bind:key="ringkasanp.id"
                  >
                    <td>{{ ringkasanp.nama }}</td>
                    <td>{{ ringkasanp.ringkasan }}</td>
                  </tr>
                </table>
                <hr />
              </div> -->
              <!-- List Ringkasan Hasil Tindak Lanjut Level Pelaksana -->

              <!-- List File Hasil Tindak Lanjut -->
              <!-- {{ state.detail2 }} -->
              <div class="col-md-12">File Hasil Tindaklanjut</div>
              <div class="col-md-12 pull-left">
                <table class="table table-striped">
                  <tr>
                    <th width="35%">File</th>
                    <th width="15%" class="text-center">Aksi</th>
                  </tr>
                  <span
                    v-if="state.detail2 === undefined"
                  ></span>
                  <tr
                    v-else-if="!state.detail2.length"
                    class="text-center"
                  >
                    <td colspan="4">
                      <i>File tidak ada</i>
                    </td>
                  </tr>
                  <tr
                    v-for="file in state.detail2"
                    v-bind:key="file.filename"
                    style="margin: 5px"
                  >
                    <td>{{ file.filename }}</td>
                    <td class="text-center">
                      <!-- <a
                        class="btn btn-success text-white"
                        @click="downloadForce(file.url)"
                        title="Download File"
                        ><i class="fa fa-download text-white"></i></a
                      >&nbsp; -->
                      <a
                        class="btn btn-success text-white"
                        @click="prevDoc(file.uuid)"
                        title="Download File"
                        ><i class="fa fa-download text-white"></i></a
                      >&nbsp;
                      <a
                        class="btn btn-info text-white"
                        @click="prevDoc(file.uuid)"
                        title="View File"
                        ><i class="fa fa-eye text-white"></i
                      ></a>
                    </td>
                  </tr>
                </table>
              </div>
              <!-- List File Hasil Tindak Lanjut -->

              <div class="col-md-12"></div>
            </gtTab>
          </gtTabs>
        </div>
      </div>

      <hr />
      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <div></div>
        </div>
      </div>
      <span class="pull-right">
        <button
          type="button"
          @click="showModal({ type: 'cancel' })"
          class="btn btn-inverse"
          title="Kembali"
        >
          Kembali</button
        >&nbsp;
      </span>
    </div>

    <ModalDefault
      v-show="isVisible"
      :title="modalTitle"
      :content="modalContent"
      :action="ActionChangeable"
      :confirmation="isConfirmation"
      @close="onClose"
    />
  </div>
</template>
<script>
import axios from "axios";
import { RotateSquare5 } from "vue-loading-spinner";
import { gtTab, gtTabs } from "@/components/gtTabs";
import TreeView from "@/components/tree-view/index";
import Header from "@/components/pageTitle/index";
var pdfMake = require("pdfmake/build/pdfmake");
var pdfFonts = require("pdfmake/build/vfs_fonts");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  components: {
    RotateSquare5,
    gtTab,
    gtTabs,
    TreeView,
    Header,
  },
  data() {
    return {
      myHTML: "",
      isTouched: false,
      isDisabled: false,
      isModalVisible: false,
      isVisible: false,
      modalTitle: "",
      modalContent: "",
      isConfirmation: false,
      ActionChangeable: null,
      printDispoLoading: false,
    };
  },
  computed: {
    canConfirm() {
      if (this.$store.state.profile.permissions.agenda) {
        return this.$store.state.profile.permissions.agenda.create;
      }
      return false;
    },
    canDispo() {
      if (this.$store.state.profile.permissions.agenda) {
        return this.$store.state.profile.permissions.agenda.create;
      }
      return false;
    },
    treeData() {
      return this.$store.state.agenda.detail.tracking !== undefined
        ? this.$store.state.agenda.detail.tracking
        : {};
    },
    items() {
      var data = [];
      if (this.$store.state.agenda.items) {
        for (
          let index = 0;
          index < this.$store.state.agenda.items.items.length;
          index++
        ) {
          let element = this.$store.state.agenda.items.items[index];
          data[index] = {
            id: element.id,
            agenda_number: element.agenda_number,
            nomor_surat: element.nomorsurat,
            subject: element.subject,
            sifat: element.sifat,
            tanggal_diterima: element.tanggal_diterima,
            redaksi: element.redaksi,
            asal_surat: element.asalsurat,
            confirmation: element.confirmation,
            confirm: element.confirm,
            close: element.close,
          };
        }
      }
      return data;
    },

    base() {
      return this.baseUrl;
    },
    state() {
      return this.$store.state.agenda;
    },
    submitted() {
      return this.formatDate(this.state.detail.created_at);
    },
    modified() {
      return this.formatDate(this.state.detail.updated_at);
    },
    tanggalsurat() {
      let text = "-";
      if (this.state.detail != undefined) {
        text = this.formatDate(this.state.detail.tanggal_surat);
      }
      return text;
    },
    tanggalditerima() {
      let text = "-";
      if (this.state.detail != undefined) {
        if (this.state.detail.tanggalditerima == "") {
          text = "";
        } else {
          text = this.formatDate(this.state.detail.tanggalditerima);
        }
      }
      return text;
    },
    tanggalselesai() {
      let text = "-";
      if (this.state.detail != undefined) {
        if (this.state.detail.tanggal_selesai == "") {
          text = "";
        } else {
          text = this.formatDate(this.state.detail.tanggal_selesai);
        }
      }
      return text;
    },
  },
  mounted() {
    this.detailLoad();
  },
  methods: {
    detailLoad() {
      const state = {
        loaded: false,
      };
      const user = localStorage.getItem("user");
      this.$store.commit("agenda/STATE", state);
      this.$store.dispatch("agenda/getAgendaTLById", this.$route.params);
    },
    onClose() {
      this.isVisible = false;
    },
    showModal(option) {
      this.isVisible = true;
      if (option.type == "cancel") {
        this.modalTitle = "Kembali?";
        this.modalContent = "<p>Apakah anda yakin ingin kembali?</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.goBack;
      }
    },

    goBack() {
      this.$router.push("/agenda");
    },
    prevDoc(string) {
      let routeData = this.$router.resolve({
        name: "preview.index",
        params: {
          document_type: "agenda",
          id: this.$route.params.id,
          file: string,
        },
      });
      console.log(routeData);
      window.open(routeData.href, "_blank");
    },
    downloadForce(url) {
      console.log(url);
      const downloadLink = document.createElement("a");

      downloadLink.href = url;
      downloadLink.target = "_blank";
      downloadLink.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },
    newTab(file) {
      window.open(file, file);
    },
  },
  watch: {
    $route: function (search) {
      this.detailLoad();
    },
  },
};
</script>
